<template>
    <div class="dashboard">
        <router-link :to="{ name: 'user-details', params: { userId: getUserId } }" class="back-btn mb-3">
            <b-icon icon="chevron-left"></b-icon>
            <span>назад к информации о пользователе</span>
        </router-link>
        <div v-if="user">
            <personal-data :userData="user"/>
        </div>
        <div v-else class="text-center">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
    import PersonalData from "@/components/forms/user/PersonalData";
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: "edit",
        components: {
            PersonalData
        },
        computed: {
            ...mapGetters([
                'userByID'
            ]),
            getUserId(){
                return this.$route.params.userId
            },
        },
        methods: {
            ...mapActions([
                'getUserById'
            ]),
        },
        mounted(){
            this.getUserById(this.getUserId).then(user => {
                this.user = user;
            });
        },
        data(){
            return {
                user: null,
            }
        }
    }
</script>

<style scoped>

</style>